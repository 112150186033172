<template>
  <header>
    <!-- Navbar Start -->
    <nav
      v-if="show_web_header == true"
      class="navbar navbar-expand-lg py-0 px-5"
    >
      <router-link
        to="/"
        class="navbar-brand d-flex align-items-center px-0 border-bottom"
        title="The Manor Belek"
      >
        <img
          class="logo"
          src="../assets/logos/the-manor-logo.png"
          alt="The Manor"
        />
      </router-link>
      <div class="border-bottom" id="navbarCollapse">
        <div class="navbar-nav ms-auto p-4 p-lg-0">
          <router-link
            to="/"
            class="nav-item nav-link active"
            :title="$t('Home')"
            >{{ $t("Home") }}</router-link
          >

          <router-link
            to="/project"
            class="nav-item nav-link"
            :title="$t('Project')"
            >{{ $t("Project") }}</router-link
          >

          <router-link
            to="/contact"
            @click="scrollToSection('contact')"
            class="nav-item nav-link"
            :title="$t('Contact')"
            >{{ $t("Contact") }}</router-link
          >
        </div>
      </div>
      <div class="col nav-item dropdown header-lang">
        <div class="nav-link dropdown-toggle" title="Change Language">
          <img alt="Language" src="../assets/world.svg" />
        </div>
        <div class="dropdown-menu bg-light m-0">
          <span
            class="dropdown-item"
            title="türkçe"
            @click="changeLanguage('tr')"
            >Türkçe</span
          >
          <span
            class="dropdown-item"
            title="english"
            @click="changeLanguage('en')"
            >English</span
          >
        </div>
      </div>
    </nav>

    <!-- mobile navbar section -->
    <nav v-if="show_mobile_header == true" id="mobile-navbar">
      <div class="container-fluid h-100">
        <div
          class="row h-100 text-center d-flex justify-content-center align-items-center"
        >
          <div class="col d-flex justify-content-center align-items-center">
            <div>
              <ul>
                <li>
                  <b-dropdown :text="this.$i18n.locale" left>
                    <b-dropdown-item
                      ><span
                        class="dropdown-item"
                        title="Türkçe"
                        @click="changeLanguage('tr')"
                        >Türkçe</span
                      ></b-dropdown-item
                    >
                    <b-dropdown-item
                      ><span
                        class="dropdown-item"
                        title="English"
                        @click="changeLanguage('en')"
                        >English</span
                      ></b-dropdown-item
                    >
                  </b-dropdown>
                </li>
              </ul>
            </div>
          </div>
          <div class="col">
            <router-link to="/" :title="$t('Home')">
              <img
                class="mobile-logo"
                src="../assets/logos/the-manor-logo.png"
                alt="logo"
              />
            </router-link>
          </div>
          <div class="col">
            <div>
              <span class="mobile-toggler" @click="TOGGLE()">⇆</span>
              <div class="col mobile-nav-items">
                <ul>
                  <li @click="TOGGLE()">
                    <router-link
                      to="/Project"
                      class="nav-item nav-link"
                      :title="$t('Project')"
                      >{{ $t("Project") }}</router-link
                    >
                  </li>
                  <li @click="TOGGLE()">
                    <router-link
                      to="/Contact"
                      class="nav-item nav-link"
                      :title="$t('Contact')"
                      >{{ $t("Contact") }}</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- Navbar End -->
  </header>
</template>
<script>
export default {
  data() {
    return {
      show_web_header: false,
      show_mobile_header: false,
    };
  },
  created() {
    // eğer ekran genişliği değiştirilirse bu event sayesinde fonksiyonu yeniden tetikliyoruz
    window.addEventListener("resize", () => {
      this.FindScreenSize();
    });
    //ilk açılışta her zaman bu kontrol gerçekleşecek
    this.FindScreenSize();
  },
  methods: {
    FindScreenSize() {
      this.show_mobile_header = window.innerWidth < 996; // window.innerWidth < 996 bu kod boolean döndürür.
      this.show_web_header = !this.show_mobile_header; // show_web_header değişkeni sürekli diğerine bağlı olarak zıt şekilde güncellenir.
    },
    changeLanguage(lang) {
      if (lang == "tr") {
        this.$i18n.locale = "tr";
        // Yeni dil değerini yerel depolamada saklayın
        localStorage.setItem("lang", this.$i18n.locale);
        return;
      }
      // Dil değiştirme işlemlerini burada yapabilirsiniz
      // Örneğin, aktif dil Türkçe ise İngilizce'ye, İngilizce ise Türkçe'ye geçebilirsiniz
      this.$i18n.locale = this.$i18n.locale = "en";
      // Yeni dil değerini yerel depolamada saklayın
      localStorage.setItem("lang", this.$i18n.locale);
    },
    scrollToSection(sectionId) {
      let hostName = window.location.pathname;
      if (hostName == "/project") {
        window.location = `/#${sectionId}`;
      } else {
        // İlgili bölümü bulmak için ID'ye göre seçim yapalım
        const section = document.getElementById(sectionId);

        if (section) {
          // Bölümü ekranın üstüne getirelim
          section.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
    TOGGLE() {
      // mobile toggle controller
      let toggle = document.querySelector(".mobile-nav-items");
      if (toggle.classList.contains("show")) {
        toggle.style.display = "none";
        toggle.classList.remove("show");
      } else {
        toggle.style.display = "block";
        toggle.classList.add("show");
      }
    },
  },
};
</script>
