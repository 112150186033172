<template>
  <section>
    <Suites />
    <div class="project-section container my-5">
      <div>
        <h2>{{ $t("ProjectDetails") }}</h2>
        <p>
          {{ $t("ProjectDetailsDesc") }}
        </p>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <figure>
            <img
              class="img-thumbnail"
              src="../assets/outside/the-manor-belek-outside-7.jpeg"
              alt=""
            />
          </figure>
        </div>
        <div class="col-lg-6 list">
          <ul>
            <li>
              <p>{{ $t("ProjectTopDesc1") }}</p>
            </li>
            <li>
              <p>{{ $t("ProjectTopDesc2") }}</p>
            </li>
            <li>
              <p>
                {{ $t("ProjectTopDesc3") }}
              </p>
            </li>
            <li>
              <p>{{ $t("ProjectTopDesc4") }}</p>
            </li>
            <li>
              <p>{{ $t("ProjectTopDesc5") }}</p>
            </li>
            <li>
              <p>{{ $t("ProjectTopDesc6") }}</p>
            </li>
            <li>
              <p>{{ $t("ProjectTopDesc7") }}</p>
            </li>
          </ul>
        </div>
      </div>
      <!-- detail boxes -->
      <div class="detail-boxes">
        <div class="box">
          <p>
            {{ $t("DetailBox1") }}
          </p>
        </div>
        <div class="box">
          <p>{{ $t("DetailBox2") }}</p>
        </div>
        <div class="box">
          <p>
            {{ $t("DetailBox3") }}
          </p>
        </div>
        <div class="box">
          <p>{{ $t("DetailBox4") }}</p>
        </div>
        <div class="box">
          <p>{{ $t("DetailBox5") }}</p>
        </div>
      </div>
      <div class="bottom-list mt-5">
        <ul style="padding-left: 15px">
          <li>
            <p>{{ $t("ProjectBottomDesc1") }}</p>
          </li>
          <li>
            <p>
              {{ $t("ProjectBottomDesc2") }}
            </p>
          </li>
          <li>
            <p>
              {{ $t("ProjectBottomDesc3") }}
            </p>
          </li>
          <li>
            <p>{{ $t("ProjectBottomDesc4") }}</p>
          </li>
          <li>
            <p>{{ $t("ProjectBottomDesc5") }}</p>
          </li>
          <li>
            <p>{{ $t("ProjectBottomDesc6") }}</p>
          </li>
          <li>
            <p>{{ $t("ProjectBottomDesc7") }}</p>
          </li>
          <li>
            <p>{{ $t("ProjectBottomDesc8") }}</p>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import Suites from "../components/Antalya-Suites-Comp.vue";
export default {
  name: "Project-Page",
  components: {
    Suites,
  },
};
</script>

<style></style>
