<template>
  <main class="main">
    <!-- main section -->
    <section class="main-section">
      <Suites />
      <div class="main-content container-fluid">
        <div class="row">
          <div class="col-lg-4 main-left">
            <h2>{{ $t("MainTitle") }}</h2>

            <p>{{ $t("MainDesc") }}</p>
            <div class="mt-1">
              <router-link tag="a" to="/Contact" :title="$t('Contact')">{{
                $t("Contact")
              }}</router-link>
            </div>
          </div>
          <div class="col-lg-8 main-right">
            <div class="slider-side">
              <VueperSlides
                class="no-shadow vueper-slide"
                :arrows="false"
                :touchable="false"
                autoplay
              >
                <VueperSlide
                  class="slide-item"
                  v-for="(slide, i) in mainSlider"
                  :key="i"
                  :image="slide.image"
                >
                </VueperSlide>
              </VueperSlides>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end main section -->

    <!-- main content bottom -->
    <section class="mt-5 main-bottom">
      <div class="text-center top mb-4">
        <p>{{ $t("MainBottomTitle") }}</p>
      </div>
      <div class="container d-flex justify-content-center">
        <div class="col-lg-3 text-center">
          <figure>
            <img src="../assets/icons/city.png" alt="city center" />
            <p>{{ $t("BelekCenter") }}</p>
          </figure>
        </div>
        <div class="col-lg-3 text-center">
          <figure>
            <img src="../assets/icons/plane.png" alt="airport" />
            <p>{{ $t("Airport") }}</p>
          </figure>
        </div>
        <div class="col-lg-3 text-center">
          <figure>
            <img src="../assets/icons/beach.png" alt="Beach" />
            <p>{{ $t("Beach") }}</p>
          </figure>
        </div>
      </div>
    </section>
    <!-- main content bottom -->

    <!-- project details -->
    <section class="container mt-5 home-project-detail">
      <div class="text-left">
        <h2>{{ $t("ProjectDetails") }}</h2>
        <p>
          {{ $t("HomeProjectDesc") }}
        </p>
      </div>
      <div>
        <div class="row">
          <div class="col-lg-6 left">
            <figure>
              <img
                class="img-thumbnail"
                src="../assets/images/home-project-detail.jpg"
                alt="project detail"
              />
            </figure>
          </div>
          <div class="col-lg-6 right">
            <div class="row">
              <div class="col-lg-6">
                <figure>
                  <img
                    class="img-thumbnail"
                    src="../assets/images/home-project-detail-2.jpg"
                    alt="project detail"
                  />
                </figure>
              </div>
              <div class="col-lg-6">
                <figure>
                  <img
                    class="img-thumbnail"
                    src="../assets/images/home-project-detail-3.jpg"
                    alt="project detail"
                  />
                </figure>
              </div>
            </div>
            <div class="col">
              <p>{{ $t("ProjectShortDesc") }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- project details -->

    <!-- rooms tab start -->
    <section id="rooms" class="tab-side container-fluid py-5">
      <div class="container">
        <h2>{{ $t("Rooms") }}</h2>
        <p>{{ $t("RoomsDesc") }}</p>
      </div>
      <div class="container-fluid tabs-bg">
        <div class="container">
          <b-tabs content-class="mt-1">
            <!-- 1+1 tab -->

            <!-- 1+1 tab -->
            <b-tab title="1+1">
              <!-- slider -->
              <VueperSlides
                :bullets="false"
                :gap="5"
                :lazy="true"
                :slideMultiple="true"
                autoplay
                :slide-ratio="1 / 4"
                class="no-shadow vueper-slide"
                :visible-slides="3"
                :dragging-distance="70"
                :breakpoints="{ 800: { visibleSlides: 3, slideMultiple: 3 } }"
              >
                <VueperSlide
                  class="slide-item"
                  v-for="(slide, i) in slides1_1"
                  :key="i"
                  :image="slide.image"
                >
                </VueperSlide>
              </VueperSlides>
            </b-tab>

            <!-- 2+1 tab -->
            <b-tab title="2+1">
              <!-- slider -->
              <VueperSlides
                :bullets="false"
                autoplay
                :gap="5"
                :lazy="true"
                :slideMultiple="true"
                :slide-ratio="1 / 4"
                class="no-shadow vueper-slide"
                :visible-slides="3"
                :dragging-distance="70"
                :breakpoints="{ 800: { visibleSlides: 3, slideMultiple: 3 } }"
              >
                <VueperSlide
                  class="slide-item"
                  v-for="(slide, i) in slides2_1"
                  :key="i"
                  :image="slide.image"
                >
                </VueperSlide>
              </VueperSlides>
            </b-tab>

            <!-- outside tab -->
            <b-tab :title="$t('Outside')">
              <!-- slider -->
              <VueperSlides
                :bullets="false"
                autoplay
                :gap="5"
                :lazy="true"
                :slideMultiple="true"
                :slide-ratio="1 / 4"
                class="no-shadow vueper-slide"
                :visible-slides="3"
                :dragging-distance="70"
                :breakpoints="{ 800: { visibleSlides: 3, slideMultiple: 3 } }"
              >
                <VueperSlide
                  class="slide-item"
                  v-for="(slide, i) in slidesOutside"
                  :key="i"
                  :image="slide.image"
                >
                </VueperSlide>
              </VueperSlides>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </section>
    <!-- rooms tabs end -->

    <!-- our services start -->
    <section id="our-services" class="container services-section mt-5">
      <div class="container-fluid my-5 p-0">
        <p>
          {{ $t("ServicesDesc") }}
        </p>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="card-item">
            <h3>{{ $t("CardItem1Title") }}</h3>
            <p>
              {{ $t("CardItem1Desc") }}
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card-item">
            <h3>{{ $t("CardItem2Title") }}</h3>
            <p>
              {{ $t("CardItem2Desc") }}
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card-item">
            <h3>{{ $t("CardItem3Title") }}</h3>
            <p>
              {{ $t("CardItem3Desc") }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- our services end -->

    <!-- contact section start -->
    <section>
      <div class="container mt-5 my-2 home-contact">
        <div class="row">
          <div class="col-lg-4">
            <p style="text-align: center">{{ $t("CreateAnAppointment") }}</p>
          </div>
          <div class="col-lg-6">
            <input
              type="email"
              placeholder="username@email.com"
              style="width: 100%"
            />
          </div>
          <div class="col-lg-2">
            <button>{{ $t("Send") }}</button>
          </div>
        </div>
      </div>
    </section>
    <!-- contact section end -->
  </main>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import Suites from "../components/Antalya-Suites-Comp.vue";
export default {
  components: {
    VueperSlides,
    VueperSlide,
    Suites,
  },
  data() {
    return {
      roomsVisibleSlides: null,
      testimonialsVisibleSlides: 3,
      mainSlider: [
        {
          image: require("../assets/main-gallery/manor-1.jpeg"),
        },
        {
          image: require("../assets/main-gallery/manor-2.jpeg"),
        },
        {
          image: require("../assets/main-gallery/manor-3.jpeg"),
        },
        {
          image: require("../assets/main-gallery/manor-4.jpeg"),
        },
        {
          image: require("../assets/main-gallery/manor-5.jpeg"),
        },
        {
          image: require("../assets/main-gallery/manor-6.jpeg"),
        },
        {
          image: require("../assets/main-gallery/manor-7.jpeg"),
        },
      ],
      slides1_1: [
        {
          image: require("../assets/rooms/the-manor-belek-room-1.jpeg"),
        },
        {
          image: require("../assets/rooms/the-manor-belek-room-2.jpeg"),
        },
        {
          image: require("../assets/rooms/the-manor-belek-room-3.jpeg"),
        },
        {
          image: require("../assets/rooms/the-manor-belek-room-4.jpeg"),
        },
        {
          image: require("../assets/rooms/the-manor-belek-room-5.jpeg"),
        },
        {
          image: require("../assets/rooms/the-manor-belek-room-6.jpeg"),
        },
        {
          image: require("../assets/rooms/the-manor-belek-room-7.jpeg"),
        },
      ],
      slides2_1: [
        {
          image: require("../assets/rooms/the-manor-belek-room-1.jpeg"),
        },
        {
          image: require("../assets/rooms/the-manor-belek-room-2.jpeg"),
        },
        {
          image: require("../assets/rooms/the-manor-belek-room-3.jpeg"),
        },
        {
          image: require("../assets/rooms/the-manor-belek-room-4.jpeg"),
        },
        {
          image: require("../assets/rooms/the-manor-belek-room-5.jpeg"),
        },
        {
          image: require("../assets/rooms/the-manor-belek-room-6.jpeg"),
        },
        {
          image: require("../assets/rooms/the-manor-belek-room-7.jpeg"),
        },
      ],
      slidesOutside: [
        {
          image: require("../assets/outside/the-manor-belek-outside-1.jpg"),
        },
        {
          image: require("../assets/outside/the-manor-belek-outside-2.jpg"),
        },
        {
          image: require("../assets/outside/the-manor-belek-outside-3.jpg"),
        },
        {
          image: require("../assets/outside/the-manor-belek-outside-4.jpg"),
        },
        {
          image: require("../assets/outside/the-manor-belek-outside-5.jpg"),
        },
        {
          image: require("../assets/outside/the-manor-belek-outside-6.jpeg"),
        },
        {
          image: require("../assets/outside/the-manor-belek-outside-7.jpeg"),
        },
        {
          image: require("../assets/outside/the-manor-belek-outside-8.jpg"),
        },
        {
          image: require("../assets/outside/the-manor-belek-outside-9.jpg"),
        },
        {
          image: require("../assets/outside/the-manor-belek-outside-10.jpeg"),
        },
        {
          image: require("../assets/outside/the-manor-belek-outside-11.jpeg"),
        },
        {
          image: require("../assets/outside/the-manor-belek-outside-12.jpeg"),
        },
        {
          image: require("../assets/outside/the-manor-belek-outside-13.jpeg"),
        },
        {
          image: require("../assets/outside/the-manor-belek-outside-14.jpeg"),
        },
        {
          image: require("../assets/outside/the-manor-belek-outside-15.jpeg"),
        },
        {
          image: require("../assets/outside/the-manor-belek-outside-16.jpeg"),
        },
        {
          image: require("../assets/outside/the-manor-belek-outside-17.jpeg"),
        },
      ],
    };
  },
  created() {
    if (window.innerWidth < 997) {
      this.roomsVisibleSlides = 1;
    } else {
      this.roomsVisibleSlides = 3;
    }
  },
};
</script>
