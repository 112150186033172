<template>
  <!-- Footer Section -->
  <footer>
    <div class="container-fluid py-5">
      <div class="row d-flex justify-content-around align-items-center">
        <div class="col-lg-6">
          <div>
            <ul class="footer-links">
              <li class="my-2">
                <router-link to="/" :title="$t('Home')">
                  {{ $t("Home") }}
                </router-link>
              </li>
              <li class="my-2">
                <router-link to="/project" :title="$t('Project')">
                  {{ $t("Project") }}
                </router-link>
              </li>
              <li class="my-2">
                <router-link to="/contact" :title="$t('Contact')">
                  {{ $t("Contact") }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="social">
            <a
              href="https://www.instagram.com/themanorbelek/"
              class=" "
              target="_blank"
              title="instagram"
            >
              <i class="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.instagram.com/themanorbelek/"
              class=""
              target="_blank"
              title="facebook"
            >
              <i class="fab fa-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/themanorbelek/"
              class=""
              target="_blank"
              title="twitter"
            >
              <i class="fab fa-twitter"></i>
            </a>
          </div>
        </div>
        <div class="col-lg-3 address">
          <h3>{{ $t("Address") }}</h3>
          <p>
            {{ $t("AddressDesc") }}
          </p>
          <p>
            {{ $t("AddressCity") }}
          </p>
        </div>

        <!-- <div class="col-lg-4 d-flex justify-content-start">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3190.0797738583146!2d30.7678!3d36.912357!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c385ba57258a2f%3A0xfaffb31841a621cb!2zQ0xBU1MgT0ZGxLBDxLBVTQ!5e0!3m2!1sen!2str!4v1688838958837!5m2!1sen!2str"
            style="border: 0"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div> -->
      </div>

      <div class="container d-flex justify-content-center my-5">
        <div class="row brands">
          <hr />

          <!-- <div class="col">
            <a href="https://garantiev.com/" target="_blank" title="Garanti Ev">
              <figure>
                <img
                  src="../assets/logos/garanti_ev_logo.png"
                  alt="Antalya Suites"
                  title="Garanti Ev"
                />
              </figure>
            </a>
          </div> -->
          <div class="col">
            <a
              href="https://antalyasuites.com/"
              target="_blank"
              title="Antalya Suites"
            >
              <figure>
                <img
                  src="../assets/logos/antalya_suites_logo.png"
                  alt="Antalya Suites"
                />
              </figure>
            </a>
          </div>
          <div class="col">
            <a href="https://geccele.com/" target="_blank" title="Geccele">
              <figure>
                <img src="../assets/logos/geccele-logo.png" alt="Geccele" />
              </figure>
            </a>
          </div>
          <!-- <div class="col">
            <a
              href="https://sertyapigrup.com/"
              target="_blank"
              title="Sert Yapi"
            >
              <figure>
                <img
                  class="small-img-1"
                  src="../assets/logos/sert-yapi-logo.png"
                  alt="Antalya Suites"
                  title="Sert Yapi"
                />
              </figure>
            </a>
          </div> -->

          <hr />
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer Section -->
</template>
<script>
export default {
  methods: {
    scrollToSection(sectionId) {
      let hostName = window.location.pathname;
      if (hostName == "/our-services") {
        window.location = `/#${sectionId}`;
      } else {
        // İlgili bölümü bulmak için ID'ye göre seçim yapalım
        const section = document.getElementById(sectionId);

        if (section) {
          // Bölümü ekranın üstüne getirelim
          section.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
  },
};
</script>
