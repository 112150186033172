<template>
  <section>
    <Suites />
    <!-- Contact start -->
    <section class="contact mt-3 mb-5" id="contact">
      <div class="container">
        <div class="col">
          <div class="my-4 mobile-start text-center">
            <h2>{{ $t("OurContactInfo") }}</h2>
            <p>The Manor Belek</p>
            <span></span>
          </div>
          <div class="row content mt-5">
            <div class="col-lg-6">
              <div class="left">
                <div class="col-lg-6">
                  <a href="tel:+905360333399" title="+90 536 033 33 99"
                    ><i class="fa fa-phone"></i> &nbsp; +90 536 033 33 99
                  </a>
                </div>
                <div class="col-lg-6">
                  <a
                    href="mailto:fahriulku@sertyapigrup.com"
                    title="fahriulku@sertyapigrup.com"
                    ><i class="fa fa-envelope"></i> &nbsp;
                    fahriulku@sertyapigrup.com
                  </a>
                </div>
                <div class="col-lg-6">
                  <p>
                    <i class="fa fa-map-marker-alt"> </i> &nbsp;
                    {{ $t("AddressDesc") }} , {{ $t("AddressCity") }}
                  </p>
                </div>
              </div>
              <div class="left-bottom">
                <figure class="col">
                  <img
                    class="img-thumbnail"
                    src="../assets/outside/the-manor-belek-outside-8.jpg"
                    alt="Location"
                  />
                </figure>
              </div>
              <div class="col redirect-img">
                <a
                  href="https://sertyapigrup.com/the-manor-belek/"
                  target="_blank"
                  title="Sert Yapi"
                >
                  <img
                    src="../assets/logos/sert-yapi-logo.png"
                    alt="Sert Yapi"
                  />
                </a>
              </div>
            </div>
            <div class="col-lg-6 form">
              <div class="pe-lg-0">
                <div class="mx-0 my-3" style="max-width: 600px">
                  <h5 class="text-left mb-0 mt-2">{{ $t("WeLoveToHelp") }}</h5>
                </div>
                <form id="myForm" ref="contactForm">
                  <div class="row g-3 text-center">
                    <div class="col-12 col-sm-6">
                      <input
                        type="email"
                        class="form-control"
                        :placeholder="$t('EmailAddress')"
                        name="email"
                        v-model="mail"
                        maxlength="50"
                        style="height: 55px"
                      />
                    </div>
                    <div class="col-12 col-sm-6">
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="$t('PhoneNumber')"
                        name="phone"
                        v-model="phone"
                        maxlength="20"
                        style="height: 55px"
                      />
                    </div>
                    <div class="col-lg-12 col-sm-6">
                      <input
                        type="text"
                        class="form-control"
                        name="name"
                        :placeholder="$t('NameSurname')"
                        v-model="name"
                        maxlength="40"
                        style="height: 55px"
                      />
                    </div>
                    <div class="col-12">
                      <textarea
                        class="form-control"
                        :placeholder="$t('Message')"
                        rows="5"
                        name="message"
                        v-model="message"
                        maxlength="250"
                      ></textarea>
                    </div>
                    <input
                      type="hidden"
                      name="website"
                      value="The Manor Belek"
                    />
                    <div
                      class="col-12 text-center d-flex justify-content-center"
                    >
                      <div class="text-center my-1"></div>
                    </div>
                  </div>
                </form>
                <button
                  class="btn py-2 w-100 text-left"
                  id="sendButton"
                  type="submit"
                  @click="sendMail"
                >
                  {{ $t("SubmitForm") }} &nbsp;
                  <i class="fa fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Contact end -->
  </section>
</template>

<script>
import emailjs from "emailjs-com";
import Suites from "../components/Antalya-Suites-Comp.vue";
export default {
  name: "Contact-Page",
  components: {
    Suites,
  },
  data() {
    return {
      mail: "",
      phone: "",
      name: "",
      message: "",
    };
  },
  created() {},
  methods: {
    async sendMail() {
      let mail = this.mail;
      // let phone = this.telephone;
      // let name = this.name;
      let message = this.message;
      if (!mail) {
        alert("E Mail cannot be empty!");
        return;
      }
      if (!message) {
        alert("Message cannot be empty!");
        return;
      }
      try {
        emailjs.sendForm(
          "service_21e6ipf",
          "template_n2ad57t",
          this.$refs.contactForm,
          "_DGqTLJBFZJpRFZJn"
        );
        this.showInfo(this.$t("MailSend"));
        // Reset form field
        this.mail = "";
        this.phone = "";
        this.name = "";
        this.message = "";
      } catch (error) {
        console.log({ error });
      }
    },
    showInfo(message) {
      event.preventDefault();
      const a = document.getElementById("sendButton");
      a.innerText = message;
      setTimeout(() => {
        const a = document.getElementById("sendButton");
        a.innerText = this.$t("SubmitForm");
      }, 2000);
    },
  },
};
</script>

<style>
</style> 