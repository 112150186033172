<template>
  <div class="main-top">
    <div class="container-fluid">
      <div class="row">
        <div class="col-11">
          <a
            href="https://antalyasuites.com/"
            target="_blank"
            :title="$t('OneClickRes')"
            >{{ $t("OneClickRes") }} <i class="fa fa-hand"></i
          ></a>
        </div>
        <div class="col-1 logo">
          <img src="@/assets/logos/suitesLogo.png" alt="Antalya Suites Logo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>